import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from 'layout';
import Header from 'components/customer/header';
import Recommendation from 'components/customer/recommendation';
import StartUsing from 'components/ui/startUsing';
import FinalOutcome from 'components/customer/finalOutcome';
import Banner from 'components/usability-testing/banner';
import {
  HeroImage,
  SectionWithRecommendation,
  DescriptionBox,
  StyledSection,
} from 'components/styled/CustomerPage';
import Container from 'components/customer/breadcrumbs';

const TalentLyft = () => {
  const { hero, mario, bruno, post } = useStaticQuery(graphql`
    query talentlyftPhotos {
      hero: file(relativePath: { eq: "talentlyft/hero.jpeg" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bruno: file(relativePath: { eq: "persons/bruno.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mario: file(relativePath: { eq: "persons/mario-buntic.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      post: markdownRemark(frontmatter: { company: { eq: "TalentLyft" } }) {
        frontmatter {
          description
          title
          slug
          company
        }
      }
    }
  `);

  const { frontmatter } = post;

  const imgSocial = process.env.GATSBY_HOST + hero.childImageSharp.fluid.src;
  const descriptionSocial = frontmatter.description;
  const { title } = frontmatter;
  const url = '/customers/talentlyft/';

  const extraMetaTags = [
    { property: 'fb:app_id', content: '926720414193529' },
    { property: 'og:type', content: 'article' },
    { property: 'og:url', content: process.env.GATSBY_HOST + url },
    { property: 'og:title', content: title },
    { property: 'og:site_name', content: 'LiveSession' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: '@LiveSessionHQ' },
  ];

  if (imgSocial) {
    extraMetaTags.push({ property: 'og:image', content: imgSocial });
    extraMetaTags.push({
      property: 'og:image:secure_url',
      content: imgSocial,
    });
    extraMetaTags.push({ property: 'og:image:width', content: 1287 });
    extraMetaTags.push({ property: 'og:image:height', content: 685 });
    extraMetaTags.push({ name: 'twitter:image', content: imgSocial });
  }

  if (descriptionSocial) {
    extraMetaTags.push({
      property: 'og:description',
      content: descriptionSocial,
    });
    extraMetaTags.push({
      name: 'twitter:description',
      content: descriptionSocial,
    });
  }

  return (
    <Layout
      metaTitle={frontmatter.title}
      titleOverride
      metaDescription={frontmatter.description}
      extraTags={extraMetaTags}
      canonical="/customers/talentlyft/"
    >
      <div className="container">
        <Container title="Talentlyft">
          <Header
            title={
              <>
                How LiveSession assisted TalentLyft to achieve <span>25.2% increase</span> in trial
                to paid customer conversions.
              </>
            }
            description="Better understanding of your users always pays off."
          />
          <HeroImage
            fluid={hero.childImageSharp.fluid}
            clasName="img-fluid"
            fadeIn
            alt="website - hero"
            title="Talentlyft team"
            style={{ maxHeight: 500 }}
            objectFit="contain"
          />
          <SectionWithRecommendation>
            <DescriptionBox>
              {/* <h2>TalentLyft impacting recruiting processes</h2> */}
              <p>
                Many companies struggle with their hiring processes. During mass recruiting it was
                always a challenge to find the relevant candidate data across multiple systems. Very
                often, the information was outdated. Recruiters were not in contact with candidates,
                in several cases where it was needed.
              </p>
              <p>
                Five years ago,{' '}
                <a
                  href="https://www.talentlyft.com/"
                  target="_blank"
                  rel="noopener noreferrer dofollow"
                >
                  TalentLyft
                </a>{' '}
                created an affordable, simple, yet multifunctional system helping manage any
                recruiting process. It is an optimal solution for small and medium-sized companies
                because of its ease of finding qualified resources, centralization of data, ability
                to communicate with candidates on a regular basis, option of publishing job offers
                on multiple internet portals, and analytics, to name a few.
              </p>
            </DescriptionBox>
            <FinalOutcome>
              <h2>Key achievements</h2>
              <ul>
                <li>
                  <p>faster and more efficient support</p>
                </li>
                <li>
                  <p>enhanced User Experience</p>
                </li>
                <li>
                  <p>list of functionalities worth to be developed in future</p>
                </li>
                <li>
                  <p>understanding of customers’ comprehension and preferences</p>
                </li>
                <li>
                  <p>insight into how to make the trial users love TalentLyft</p>
                </li>
                <li>
                  <p>
                    meaningful increase in trial to paid conversion - <strong>25.2%</strong>
                  </p>
                </li>
              </ul>
            </FinalOutcome>
          </SectionWithRecommendation>

          <StyledSection>
            <h2>Striving to be better and better</h2>
            <p>
              Any great software can be made better, so TalentLyft wanted to find out what users’
              actual experience with their product was. First, they wanted to understand what their
              trial users’ impressions were. They wanted them to be captivated as soon as they
              logged into the service. Another issue was with regular users. It seemed that they
              were not fully aware of all the possibilities that TalentLyft had offered. It looked
              as if, for some reason, they were overlooking some elements of the software that might
              be useful for them. TalentLyft sought to learn their users’ journey.
            </p>
            <p>
              They also wanted to improve their support. In some cases, when users reported
              problems, it was difficult for support specialists to figure out how a given issue
              occurred. To understand the problem and help customers, the support team had to ask a
              lot of questions. This was time consuming and required extra effort from a user to
              answer all the questions.
            </p>
          </StyledSection>
          <StyledSection>
            <h2>In search of the right tool</h2>
            <p>
              It was clear that the software, that provided the data about user journey and customer
              behavior, would bring a lot of value. TalentLyft started a search for a solution which
              would provide good filters and search options while being simple to implement and use.
              After careful selection, they chose LiveSession. One reason for this choice was the
              set of features that helped TalentLyft to find the sessions they were looking for.
              Another one was the affordable pricing.
            </p>
            <Banner
              title="Are you curious of your customers' experience with your product?"
              style={{ marginBottom: 0 }}
            />
          </StyledSection>
          <StyledSection>
            <h2>Getting immediate results</h2>
            <p>
              After a two-minute installation, LiveSession was ready to use. Since TalentLyft was
              already using HubSpot, MediaToolkit, Productboard, Jira, and Intercom, they decided to
              integrate their new software. The first integration was with{' '}
              <a href="/integrations/intercom/" target="_blank" rel="noopener noreferrer">
                Intercom
              </a>
              . This paid off immediately, because the support team could work faster without
              involving users. Every time a user sent an Intercom message, a link to his session was
              automatically added to the conversation. That way, even if the chat messages were not
              clear enough, the support specialist could see what had actually happened during the
              troublesome session.
            </p>
          </StyledSection>

          <Recommendation
            recommendation="Sometimes, given errors happened only on a certain combination of Operation System and browser If it was not for LiveSession, we would not have been able to replicate the issue."
            person="Bruno Ilisevic"
            job="Product Manager"
            company="TalentLyft"
            url="http://talentlyft.com/"
            image={bruno.childImageSharp.fluid}
          />

          <StyledSection>
            <h2>Gaining valuable insight with LiveSession</h2>
            <p>
              <strong>Sessions</strong> has become the most favorite LiveSession feature for
              TalentLyft. Using it, they were able to learn what their users were doing on the
              website. Thanks to filtering, they could focus on observation of the most important
              elements of their software and optimize the UX.
            </p>
            <p>
              Observing user sessions revealed that many of TalentLyft customers were not using
              filters. They were not visible enough and required several clicks to reach them. In
              addition, those users who began using them, were somewhat confused about the meaning
              of several labels. They unexpected that TalentLyft differentiated terms, such as
              “website” and “webpage” which were considered synonyms by many people. The customers
              were not as familiar with logical operators to use them efficiently, as IT developers
              were.{' '}
            </p>
            <p>
              TalentLyft discovered that most non-technical users, who were used to finding
              candidates by search functionality, preferred to look for jobs on the dashboard in a
              similar way. Some customers were not familiar with CTRL+F shortcut, provided by most
              popular browsers and programs. Therefore, they became frustrated because of inability
              to quickly find the job they were looking for. As many of TalentLyft developers were
              used to CTRL+F shortcut, the development of job search functionality initially seemed
              to be a low priority. After analyzing many sessions the priority to develop a job
              search option became more important and urgent.
            </p>
            <p>
              Another valuable insight was that the trial users were not able to see all benefits of
              TalentLyft due to lack of demo data to try it out.
            </p>
            <Banner
              title="Do you want your trial users to love your software?"
              style={{ marginBottom: 0 }}
            />
          </StyledSection>

          <StyledSection>
            <h2>Implementing improvements</h2>
            <p>
              With much knowledge from LiveSession, TalentLyft began working on improvements. To
              engage trial users, more demo data was provided. This gave potential customers a
              chance to see the value of TalentLyft just after registration. They were able to test
              more software features without the need to create and upload their own data .
            </p>
            <p>The next step was to redesign elements of software that have been confusing.</p>
            {/* <StyledImg
              fluid={img1.childImageSharp.fluid}
              fadeIn
              alt="Databox app"
              title="Databox business plaform"
              className="img-fluid"
            /> */}
          </StyledSection>

          <Recommendation
            recommendation="With LiveSession, we can work in a continuous improvement approach mode. When we plan the development of new functionalities, we investigate how similar existing features are used, so we can make the new ones even more user-friendly."
            person="Mario Buntic"
            job="CEO"
            company="Talentlyft"
            url="http://talentlyft.com/"
            image={mario.childImageSharp.fluid}
          />
          <FinalOutcome lastSection>
            <h2>Final outcome</h2>
            <p>
              All these implemented improvements, which were based on information provided by
              LiveSession, have yielded tangible results for TalentLyft. Current users have become
              more satisfied with their product. Potential customers have been even more impressed
              the first time they interacted with the software. More demo data allowed them to try
              more functionalities out of the box. The removal of confusing software elements, and
              improved UX made them more willing to pay for TalentLyft. User conversions from trial
              to paid accounts have increased by 25.2% and there is a good chance they will grow
              even more in coming weeks.{' '}
              <i>“Better understanding of your users always pays off.”</i> – summarized Bruno
              Ilišević.
            </p>
          </FinalOutcome>
        </Container>
      </div>
      <StartUsing />
    </Layout>
  );
};

export default TalentLyft;
